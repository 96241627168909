<!--
 * @Author: yuwenwen
 * @Date: 2022-11-10 11:21:38
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-01-03 17:24:23
 * @FilePath: \nc-funeral-mobile\src\views\complaint\details.vue
-->
<template>
  <div class="page-container page-container-100">
    <div class="policy">
      <div class="policy-article-title text-ellipse-2">
        {{complaintForm.reportName}}
      </div>
      <div class="policy-article-datetime">{{complaintForm.createTime}}</div>
      <div class="policy-article-content">
        {{complaintForm.reportRemarks}}
      </div>
    </div>
  </div>
</template>
<script>
import {getComplaintDetails} from '@/api/complaint.js'
export default {
  name: "Policy",
  data() {
    return {
      complaintId:'',
      complaintForm:{}
    };
  },
  created() {
    let {id} = this.$route.query;
    this.complaintId = id;
    this.getComplaintDetails();
  },
  mounted() {},
  methods: {
    // 投诉建议详情
    async getComplaintDetails(){
      let [err,res] = await this.$awaitWrap(getComplaintDetails(this.complaintId));
      if(err){
        return;
      }
      this.complaintForm = res.result;
    }
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/styles/index.less";
</style>